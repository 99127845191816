/*
 * @Author: leo
 * @Date: 2022-03-15 15:29:07
 * @LastEditors: leo
 * @LastEditTime: 2022-03-04 01:11:12
 * @Description:
 */
import emotionData from './emoji-data'

const escapeRegExp = (text) => {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const emojiAllInfo = {};
const nameResult = [];
emotionData.forEach((item) => {
    for (const key in item) {
        const val = item[key];
        if (/cn|hk|us|code|web_code/.test(key) && val) {
            const { className='', emoji='' } = item;
            emojiAllInfo[val] = { className, emoji };
            if (!/\[.*?\]/.test(val)) {
                nameResult.push(escapeRegExp(val));
            }
        }
    }
});

nameResult.unshift('\\[.*?\\]')
export const emojiReg = new RegExp(nameResult.join('|'), 'ig');
