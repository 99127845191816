<template>
    <div class="linkCouponWrapper" :class="{ showInstructions: show_instructions }">
        <div class="linkCouponInner">
            <div class="couponTopWrap">
                <div class="linkCoupon_top">
                    <div class="linkCoupon-shopInfo-wrapper">
                        <div class="linkCoupon_shopInfo">
                            <img class="linkCoupon_shop_logo" v-default-avatar :src="distributerInfo.avatar" />
                            <span class="linkCoupon_shop_name">
                                {{ distributerInfo.name | handleNickName }}
                            </span>
                            <span>的推广优惠券</span>
                        </div>
                        <div class="linkCoupon_shopInfo" :class="{ sharebtnBox: !posterOpacity }">
                            <span class="linkCoupon_shareBtn">
                                剩余数量:{{ couponInfo.remaining_number }}/{{ couponInfo.stock }}张
                            </span>
                        </div>
                    </div>
                    <div class="line"></div>
                </div>
                <coupon-promotion-content v-if="this.info.content" :info="info" />
                <div class="linkCouponBox">
                    <div class="couponValue">
                        <div class="couponPrice">
                            <template v-if="couponInfo.discount_way === 1">
                                <span class="t5">￥</span>
                                <span
                                    :class="[
                                        'curPrice curPriceLeft curLayout',
                                        {
                                            fontSmall: (couponInfo.price / 100).toString().length > 5,
                                            fontLastSmall: (couponInfo.price / 100).toString().length > 7
                                        }
                                    ]"
                                >
                                    {{ couponInfo.price | ToFilter }}
                                    <span class="fixedfont" v-if="couponInfo.price % 100 != 0">
                                        .{{ fixedFont(couponInfo.price) }}
                                    </span>
                                </span>
                            </template>
                            <span
                                :class="[
                                    'curPrice curLayout',
                                    {
                                        fontSmall: (couponInfo.price / 100).toString().length > 5,
                                        fontLastSmall: (couponInfo.price / 100).toString().length > 7
                                    }
                                ]"
                                v-if="couponInfo.discount_way === 2"
                            >
                                {{ couponInfo.discount_percent | ToFilter(false) }}
                                <span class="fixedPercent" v-if="couponInfo.discount_percent % 10 !== 0">
                                    .{{ fixedFont(couponInfo.discount_percent, false) }}
                                </span>
                                <span class="discountFont">折</span>
                            </span>
                        </div>
                        <div v-if="couponInfo.require_price == 0" class="couponUseCondition t3">无门槛</div>
                        <div v-if="couponInfo.require_price != 0" class="couponUseCondition t3">
                            满{{ couponInfo.require_price / 100 }}元可用
                        </div>
                    </div>
                    <div class="couponInfo">
                        <span class="couponName">{{ couponInfo.title }}</span>
                        <div class="coupon_otherInfo">
                            <span v-if="couponInfo.type == 0">店铺内部分商品可用</span>
                            <span v-if="couponInfo.type == 1">店铺内全部商品可用</span>
                            <span class="couponUseTime" v-if="couponInfo.valid_day">
                                {{ couponInfo.valid_after === 1 ? `领取次日` : `领取后`
                                }}{{ couponInfo.valid_day }}天内有效
                            </span>
                            <span class="couponUseTime" v-else>
                                {{ couponInfo.valid_at | filterDate }}-{{ couponInfo.invalid_at | filterDate }}
                            </span>
                            <div
                                v-if="couponInfo.instructions !== ''"
                                @click="show_instructions = !show_instructions"
                                class="coupon_showInstructions"
                            >
                                <div class="couponUse">使用说明</div>
                                <img :class="{ close: show_instructions }" src="~imgPath/coupon/shopname_arrow.png" />
                            </div>
                        </div>
                    </div>
                    <p class="disabledStatus" v-show="is_unavaile"></p>
                </div>
                <transition name="fade">
                    <div v-if="show_instructions" class="couponInstructions">
                        <span v-html="replaceInstruction(couponInfo.instructions) || '暂无使用说明'"></span>
                    </div>
                </transition>
            </div>
            <div class="couponStateWrap">
                <div class="poverDailog">
                    <span class="couponProgressDesc"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fetchCouponView } from '../../../api/index.js';
import { getCookie } from '@/utils/cookies';
import CouponPromotionContent from './couponPromotionContent'; //优惠券话术模块

export default {
    props: {
        info: {
            type: Object,
            default: () => {}
        }
    },
    filters: {
        filterDate(val) {
            // eslint-disable-next-line no-extra-boolean-cast
            if (!!val) return val.split(' ')[0];
            return val;
        },
        //名字过长处理
        handleNickName(val) {
            if (val && val.length > 5) {
                return val.substring(0, 5) + '...';
            }
            if (!val) {
                return '你';
            }
            return val;
        },
        // 做数值过滤，value-要处理的数字 ToFix2-默认保留两位，默认转分转元，除以100。其他除以10(如折扣)
        ToFilter: (value, ToFix2 = true) => {
            let result = (value / 100).toString();
            if (!ToFix2) {
                result = (value / 10).toString();
            }
            if (result.indexOf('.') > 0) {
                return result.split('.')[0];
            } else {
                return result;
            }
        }
    },
    components: {
        CouponPromotionContent
    },
    computed: {
        // 优惠券领取总数是否超过优惠券总数
        getPercent() {
            return this.couponInfo.remaining_number == 0;
        },
        // 优惠券是否过期
        isExpire() {
            // eslint-disable-next-line no-useless-escape
            let timeStr = this.couponInfo.invalid_at.replace(/\-/g, '/');
            let status = new Date() > new Date(timeStr);
            return status;
        },
        // 优惠券是否被手动停止
        isStop() {
            return this.couponInfo.manual_stop_at || this.is_stop;
        }
    },
    data() {
        return {
            couponState: 2,
            stock: 20,
            is_unavaile: false,
            is_received: false,
            store_info: {
                store_logo: 'https://www.baidu.com/img/PCfb_5bf082d29588c07f842ccde3f97243ea.png',
                store_name: 'nexo'
            },
            posterOpacity: 1,
            is_overcount: false,
            is_overdue: false,
            show_instructions: false,
            distributerInfo: {
                userId: 123,
                avatar: '',
                nickname: '你'
            },
            couponInfo: {
                // 优惠券信息
                id: '', // 优惠券id
                title: '', // 优惠券名称
                type: 0, // 使用类型 0-指定商品可用 1-全部可用 2-指定不可用
                discount_way: 1, // 折扣方式1-满减 2-折扣
                discount_percent: 0, // 优惠券打几折，折扣力度百分比 0-100
                discount_limit_price: 0, // 折扣上限, 最多优惠金额 0-表示不限制
                valid_after: 0, // 领取后多久后生效，单位: 天， 0-表示立即生效 -1-表示使用优惠券设置的有效期
                valid_day: 0, // 领取后该券最多有效期天数
                valid_at: 'null', // 优惠券起效时间
                invalid_at: 'null', // 优惠券失效时间
                price: 0, // 满减券抵扣金额，单位：分
                require_price: 0, // 使用条件--要求价格 0-无限制
                stock: 0, // 优惠券数量
                remaining_number: 0 // 已领取优惠券数量
            }
        };
    },
    mounted() {
        this.takeCouponBaseInfo();
    },
    methods: {
        //非自己的就跳回去
        judgeIsMyActivity(isMyselfActivity) {
            if (!isMyselfActivity) {
                this.$router.push('/staff_promotion');
            }
        },
        passCouponState() {
            this.$emit('changeBtnStatus', this.couponState);
        },
        // 小数点后的样式补充 ToFix2-默认保留2位,其他保留1位
        fixedFont(value, ToFix2 = true) {
            let result = (value / 100).toString();
            if (!ToFix2) {
                result = (value / 10).toString();
            }
            if (result.toString().indexOf('.') > -1) {
                return result.split('.')[1];
            } else {
                return '';
            }
        },
        handleResCouponData(couponData) {
            this.judgeIsMyActivity(couponData.is_assigned);
            this.distributerInfo = { ...couponData.user_info };
            this.couponInfo = { ...couponData.coupon_info };
            this.couponState = couponData.coupon_state;
            this.passCouponState();
        },
        async takeCouponBaseInfo() {
            let params = {
                user_id: getCookie('with_app_id'),
                coupon_id: this.$route.params.coupon_id
            };
            let res = await fetchCouponView(params, 'COUPONBASEINFO');
            if (res.code == 0) this.handleResCouponData(res?.data);
        },
        replaceInstruction(str) {
            if (!str) {
                return '';
            }
            return str.replace(/\r\n/g, '<br/>');
        },
        sharePoster() {
            console.log('1');
        }
    }
};
</script>
<style lang="scss" scoped>
.linkCouponWrapper {
    width: 100%;
    // height: 7.68rem;
    background: url('~imgPath/coupon/getCouponBg.png') no-repeat;
    background-size: 100% 6.12rem;
    box-sizing: border-box;
    padding: 0.48rem 0.32rem 0;
    .linkCoupon_top {
        box-sizing: border-box;
        font-size: 0.28rem;
        height: 1.6rem;
        padding: 0.3rem 0.48rem 0 0.48rem;
        // padding-bottom: 0.12rem;
        // border-bottom: 0.03rem dashed #ffe6cc;
        background-repeat: repeat-y;
        -webkit-background-size: 100% 100%;
        background-size: 110% 100%;
        background-image: url('~imgPath/coupon/bg-top.png');
        background-position-x: -18px;
        background-position-y: 0;
        border-top-left-radius: 0.24rem;
        border-top-right-radius: 0.24rem;
        position: relative;
        top: -0.28rem;
        .linkCoupon-shopInfo-wrapper {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            margin: 0.34rem 0 0.2rem 0;
        }
        .line {
            border-bottom: 0.03rem dashed #ffe6cc;
            // margin:0.48rem;
        }
    }
    .linkCouponInner {
        width: 100%;
        // height: 100%;
        // background: url('~imgPath/coupon/inner_bg_l.png') no-repeat;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        border-radius: 0.24rem;
        background: #fff;
        box-shadow: 0px 2px 12px 0px rgba(250, 103, 5, 0.6);

        background-repeat: repeat-y;
        -webkit-background-size: 100% 100%;
        background-size: 110% 100%;
        background-image: url('~imgPath/coupon/bg-bottom.png');
        background-position-x: -18px;
        background-position-y: 0;
        .linkCoupon_shopInfo {
            display: flex;
            align-items: center;
        }
        .sharebtnBox {
            position: relative;
            z-index: 2;
        }
        .linkCoupon_shop_logo {
            width: 0.56rem;
            height: 0.56rem;
            margin-right: 0.06rem;
            border-radius: 50%;
            border: 1px solid #eee;
        }
        .linkCoupon_shop_name {
            display: block;
            color: #353535;
            font-size: 0.28rem;
            max-width: 2.6rem;
            font-weight: 500;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
        }
        .linkCoupon_arrow {
            width: 0.32rem;
            height: 0.32rem;
        }
        .linkCoupon_shareBtn {
            font-size: 0.28rem;
            color: #ff781f;
            text-align: center;
        }
        .linkCoupon_shareImg {
            width: 0.32rem;
            height: 0.32rem;
            margin-right: 0.08rem;
        }
        .gap {
            width: 0.24rem;
            height: 0.24rem;
            border-radius: 50%;
            background-color: #ffac6d;
            position: absolute;
            top: 2.5rem;
        }
        .linkCouponBox {
            display: flex;
            position: relative;
            // width: 100%;
            height: 2.16rem;
            margin: 0.48rem 0.48rem 0.24rem;
            border-radius: 0.08rem;
            overflow: hidden;
            background-color: #fff6f0;
            // border: 1px solid#FFC299;
            .couponValue {
                width: 2.16rem;
                height: 100%;
                box-sizing: border-box;
                // margin-right: 1px;
                background: #ff781f;
                border: 1px solid #ff781f;
                color: #fff;
                .couponPrice {
                    position: relative;
                    width: fit-content;
                    white-space: nowrap;
                    height: 1.18rem;
                    margin: 0.18rem auto 0.08rem;
                    font-size: 0.88rem;
                    text-align: center;
                    .t5 {
                        font-size: 0.32rem;
                    }
                    span {
                        position: relative;
                    }

                    .curPriceLeft {
                        // margin-left: -0.2rem;
                    }

                    .curLayout {
                        display: -webkit-inline-box;
                        display: -ms-inline-flexbox;
                        display: -webkit-inline-flex;
                        display: inline-flex;
                        -webkit-box-align: baseline;
                        -webkit-align-items: baseline;
                        -ms-flex-align: baseline;
                        align-items: baseline;
                    }

                    .fontSmall {
                        font-size: 0.48rem !important;
                    }

                    .fontLastSmall,
                    .fixedPercent {
                        font-size: 0.32rem !important;
                    }

                    .fixedfont {
                        font-size: 0.24rem !important;
                    }
                    .discountFont {
                        font-size: 0.32rem;
                        margin-left: 0.02rem;
                    }
                }

                .couponUseCondition {
                    width: 100%;
                    text-align: center;
                    overflow: hidden;
                    max-height: 0.7rem;
                    overflow: hidden;
                }
            }

            .couponInfo {
                z-index: 2;
                display: flex;
                flex-direction: column;
                width: 3.76rem;
                height: 100%;
                box-sizing: border-box;
                padding: 0 0.32rem;
                justify-content: center;
                border-radius: 0 0.08rem 0.08rem 0;
                border: 1px solid #ffc299;
                border-left: none;
                color: #353535;
                .couponName {
                    font-size: 12px;
                    color: #505050;
                    width: 100%;
                    font-size: 0.24rem;
                    font-weight: 500;
                    // line-height: 0.44rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 8px;
                }
                .coupon_otherInfo {
                    font-size: 0.2rem;
                    display: flex;
                    color: #666;
                    flex-direction: column;
                    .coupon_showInstructions {
                        display: flex;
                        align-items: center;
                        img {
                            transform: rotate(90deg);
                            display: block;
                            height: 0.28rem;
                            width: 0.28rem;
                        }
                        .close {
                            transform: rotate(-90deg);
                        }
                    }
                }
                .couponUseTime {
                    margin-top: 2px;
                    margin-bottom: 7px;
                    width: 100%;
                    // line-height: 0.34rem;
                }
                .couponUse {
                    margin-right: 0.16rem;
                }
            }

            .hasReceivedStatus {
                width: 0.96rem;
                height: 0.96rem;
                position: absolute;
                right: 0.16rem;
                bottom: 0.16rem;
                background: url('~imgPath/coupon/received_pin.png') center no-repeat;
                background-size: 100%;
                z-index: 1;
            }
            .disabledStatus {
                width: 0.96rem;
                height: 0.96rem;
                position: absolute;
                right: 0.16rem;
                bottom: 0.16rem;
                background: url('~imgPath/coupon/disabled_pin.png') center no-repeat;
                background-size: 100%;
            }
        }
        .couponInstructions {
            padding: 0.34rem 0.32rem 0.4rem;
            background: #fff5e5;
            border-radius: 0 0 0.08rem 0.08rem;
            font-size: 0.2rem;
            color: #666;
            margin: 0 0.48rem;
            span {
                word-wrap: break-word;
                word-break: break-all;
                white-space: pre-wrap;
            }
        }
        .couponTopWrap {
            // padding: 0.32rem 0.48rem 0.24rem;
            justify-content: center;
        }
        .fade-enter-active,
        .fade-leave-active {
            transition: opacity 0.5s;
        }
        .fade-enter,
        .fade-leave-to {
            opacity: 0;
        }
        .couponStateWrap {
            background: url(https://wechatapppro-1252524126.file.myqcloud.com/appAKLWLitn7978/image/b_u_5b2225aa46488_oGKN7IvA/krlsg9bn02ph.png);
            background-size: 100% 100%;
            padding: 0 0.32rem 0.64rem 0.32rem;
        }
        .integral-redreem-num {
            margin-top: 0.24rem;
            color: #fff;
            text-align: center;
        }
        .poverDailog {
            font-weight: 600;
            color: #fff;
            font-size: 0.28rem;
            padding-top: 0.7rem;
        }
        span {
            display: inline-block;
        }
        .disabledCouponBox {
            opacity: 0.4;
            // background: #f6f6f6;
            // border: 1px solid #f6f6f6;
        }
    }
}
.couponProgress {
    position: relative;
    margin-top: 0.08rem;
    width: 100%;
    height: 0.16rem;
    // margin-top: 1.2rem;
    border-radius: 0.08rem;
    background: #fff;
    .progressing {
        position: absolute;
        top: 0;
        left: 0;
        // width: 50%;
        height: 0.16rem;
        background: #f6bc41;
        background-image: repeating-linear-gradient(
            120deg,
            transparent 0,
            transparent 0.16rem,
            #f8cc46 0.16rem,
            #f8cc46 0.32rem
        );
        border-radius: 0.08rem;
    }
}
.showInstructions {
    .linkCouponInner {
        // background-repeat: repeat-y;
        // background-size: 107% 135%;
        // background-image: url(https://wechatapppro-1252524126.file.myqcloud.com/appAKLWLitn7978/image/b_u_5b2225aa46488_oGKN7IvA/krlq1doj0q06.png);
        // background-position-x: -13px;
        // background-position-y: -11px;
        // .linkCoupon_top {
        //     border: none;
        // }
        .couponTopWrap {
            background-image: none;
        }
        .linkCouponBox {
            margin-bottom: 0;
        }
    }
}
.boxTwo {
    justify-content: space-between;
}
.boxOne {
    justify-content: center;
}
.boxMargin {
    padding-top: 1.2rem !important;
}
.jus-center {
    justify-content: center;
}

.limitTipsDialog {
    z-index: 102;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 288px;
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    background-color: #fff;
    transform: translate(-50%, -50%);
    .title {
        font-size: 16px;
        color: #333333;
        text-align: center;
        line-height: 24px;
        margin-top: 24px;
        margin-bottom: 8px;
        font-weight: 600;
    }
    .text {
        font-size: 14px;
        color: #666666;
        letter-spacing: 0;
        text-align: center;
        line-height: 24px;
        padding: 0 16px;
    }
    .mgb-24 {
        margin-bottom: 24px;
    }
    .btnGroup {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #eee;
        font-size: 14px;
        .giveUp {
            color: #999999;
            flex: 1;
            text-align: center;
            border-right: 1px solid #eee;
            line-height: 47px;
        }
        .register {
            color: #105cfb;
            flex: 1;
            text-align: center;
            line-height: 47px;
            font-weight: 600;
        }
    }
}
.mask {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 101;
}
</style>
