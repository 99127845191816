<template>
    <div class="activity-detail-box" v-loading="isLoading">
        <div class="activity-detail">
            <coupon v-if="isCoupon" class="nexo" :info="info" @changeBtnStatus="changeBtnStatus" />
            <activity-introduce v-if="!isCoupon" :info="info"></activity-introduce>
            <content-detail v-if="!isCoupon" :info="info" :distributeUrl="distributeUrl"></content-detail>
            <div :class="[isCoupon ? 'coupon-margin' : 'normal-margin']">
                <business-opportunity
                    :couponOverview="couponOverview"
                    :isGray="grayStatus"
                    :showMyData="true"
                    :businessData="businessDetail"
                    :isActivityDetail="true"
                    :isInList="isInList"
                    :isCoupon="isCoupon"
                    :isActivity="true"
                    :microCouponData="microCouponData"
                />
            </div>
            <!-- 排行列表 -->
            <rank-list
                v-if="isInList"
                :visitor-data="visitorRankData"
                :business-data="businessRankData"
                :user-visitor-data="userVisitorRankData"
                :user-business-data="userBusinessRankData"
                @businessRank="getBusinessRankData"
                @tab-switch="tabSwitch"
            />
            <invite-customer
                :isCoupon="isCoupon"
                :isInList="isInList"
                :fetchParams="params"
                @transGray="getGray"
                :isJoin="isJoin"
                :current-tab="currentTab"
                @loadedActivityList="loadedActivityList"
                :isMicroPage="isMicroPage"
                :hasMicroCoupon="microCouponData.hasCoupon"
            ></invite-customer>
        </div>
        <invitate-dialog
            ref="invitateDialog"
            v-model="isShowDialog"
            :distributeUrl="distributeUrl"
            :isInList="isInList"
            :activityType="isCoupon ? 'coupon' : 'activity'"
            :posterData="posterData"
            @showWxShare="isShowWxShare = true"
            :share_content="info.content"
            :showCopyTextLink="showCopyTextLink"
        />
        <div class="background-img">
            <img src="" alt="" />
        </div>
        <wx-share-dialog :isShowWxShare="isShowWxShare" @close="isShowWxShare = false"></wx-share-dialog>
        <promotionCenterBtn />
        <bottom-btn v-if="showBtn" @goPromote="goPromote" :state="state" :couponState="couponState"></bottom-btn>
    </div>
</template>

<script>
import coupon from './components/coupon.vue';
import activityIntroduce from './components/activityIntroduce.vue';
import bottomBtn from './components/bottomBtn.vue';
import inviteCustomer from './components/inviteCustomer.vue';
import invitateDialog from '../../components/invitateDialog.vue';
import '@xiaoe/venus/dist/xiaoe/ui/h5/share-poster/share-poster.css';
import wxShareDialog from './components/wxShareDialog.vue';
import contentDetail from './components/contentDetail.vue';
import promotionCenterBtn from '../../components/promotionCenterBtn.vue';
import permission from '@/mixins/permission';
import businessOpportunity from './components/businessOpportunity.vue';
import rankList from '../../components/rankList.vue';
import {
    fetchActivityDetail,
    fetchDistributeUrl,
    fetchMicroCoupon,
    microCouponType,
    fetchCouponView,
    fetchDistributeAvatar
} from '../../api/index';
import { getCookie } from '@/utils/cookies';

export default {
    mixins: [permission],
    components: {
        coupon,
        activityIntroduce,
        bottomBtn,
        inviteCustomer,
        invitateDialog,
        wxShareDialog,
        contentDetail,
        promotionCenterBtn,
        businessOpportunity,
        rankList
    },
    data() {
        return {
            couponOverview: {
                total_merchant_num: 0, // 商机总数
                today_merchant_num: 0, // 今日新增商机数
                total_visitor_num: 0, // 访客总数
                today_visitor_num: 0, // 今日新增访客数
                total_received_num: 0, // 领券用户总数
                today_received_num: 0, // 今日新增领券用户数
                total_used_num: 0, // 用券用户总数
                today_used_num: 0 // 今日新增用券用户总数
            },
            isCoupon: !!this.$route.params.coupon_id, // 是否是优惠券类型活动
            grayStatus: false,
            isInList: false,
            showBtn: false,
            info: {
                activityTitle: '',
                num: '',
                label: [],
                resourceTitle: '',
                resourceType: '',
                content: '', // 话术
                introduce: '', // 介绍
                isJoin: '',
                total_promoters: 0
            },
            customerList: [],
            promoteList: [],
            posterData: {},
            distributeUrl: '',
            isLoading: false,
            isShowDialog: false,
            isShowWxShare: false,
            isJoin: false,
            state: 0, // 0-活动有效 1-活动失效
            // 排行榜数据
            visitorRankData: [],
            userVisitorRankData: {},
            businessRankData: [],
            userBusinessRankData: {},
            currentTab: {},
            isMicroPage: false, // 活动是否关联的微页面
            isMicroLoading: false,
            microCouponData: {
                receiveTotal: 0, // 领取总数
                useTotal: 0, // 使用总数
                hasCoupon: false // 是否关联了优惠券
            },
            couponState: 2
        };
    },
    async created() {
        this.isCoupon = !!this?.$route?.params?.coupon_id;
        let appNode = document.querySelectorAll('.app')[0];
        if (appNode) appNode.style = 'position: relative !important;';
        this.isLoading = true;
        await this.hasPermission((data) => {
            const { is_distributor, status, manage_info } = data;
            if (is_distributor === 0 && status === 2) {
                this.$router.replace({
                    name: 'noPermissionPage',
                    query: {
                        manager: manage_info.name
                    }
                });
            }
            if (is_distributor === 1 && status === 2) {
                this.$router.replace({
                    name: 'noPermissionPage',
                    query: {
                        manager: manage_info.name
                    }
                });
            }
            if (is_distributor === 0 && status === 0) {
                this.$router.replace({
                    name: 'noPermissionPage',
                    query: {
                        manager: manage_info.name
                    }
                });
            }
        });
        this.getDistributeAvatar();
        this.loadInfo();
        this.getVisitorRankData();
    },
    mounted() {
        if (this.isCoupon) this.takeCouponOverView();
        setTimeout(() => {
            this.showBtn = true;
        }, 2000);
    },
    computed: {
        businessDetail() {
            return this.$store.getters.getBusinessDetail;
        },
        params() {
            let activity_id = this.$route.params.id;
            return {
                activity_id
            };
        },
        requestParams() {
            return {
                app_id: getCookie('with_app_id'),
                user_id: localStorage.getItem('userId'),
                activity_id: this.$route.params.id
            };
        },
        //是否展示“复制链接及话术”按钮
        showCopyTextLink() {
            return Boolean(this.info.content && this.isJoin);
        }
    },
    methods: {
        // 请求 后端转过的 企微头像地址
        getDistributeAvatar() {
            let app_id = getCookie('with_app_id');
            let user_id = localStorage.getItem('userId');
            fetchDistributeAvatar({
                app_id,
                user_id
            })
                .then((res) => {
                    if (res.code === 0) {
                        this.avatarSrc = res?.data.staff_avatar ?? '';
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // 未参与推广时 一键复制需要调接口获取推广链接
        async bindDistribute() {
            if (this.isJoin) return;
            try {
                let res = await fetchDistributeUrl(this.params);
                if (res.code === 0) {
                    this.isJoin = true;
                    this.distributeUrl = res.data.distribute_url;
                }
            } catch (e) {
                console.log(e);
            }
            return true;
        },
        tabSwitch(tab) {
            this.currentTab = tab;
        },
        changeBtnStatus(val) {
            this.couponState = val;
        },
        async takeCouponOverView() {
            let params = {
                user_id: getCookie('with_app_id'),
                coupon_id: this.$route.params.coupon_id
            };
            let res = await fetchCouponView(params, 'COUPONOVERVIEW');
            if (res.code == 0) this.couponOverview = res?.data;
        },
        getGray(data) {
            this.grayStatus = data;
        },
        loadInfo() {
            Promise.all([fetchActivityDetail(this.params)])
                .then((res) => {
                    this.isInList = res[0]?.data?.open_wework_distribute ?? false;
                    this.handleActivityDetail(res[0].data);
                    // 活动类型为微页面
                    if (this.isMicroPage) {
                        this.getMicroCouponData();
                    }
                    if (this.$store.state.isSetToDetail) {
                        this.goPromote();
                        this.$store.commit('setIsSetToDetail', false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoading = false;
                });
        },
        async getMicroCouponData() {
            this.isLoading = true;
            this.isMicroLoading = true;
            const res = await fetchMicroCoupon(this.requestParams, microCouponType.get(0)).finally(() => {
                this.isMicroLoading = false;
                this.isLoading = false;
            });
            if (res.code === 0) {
                this.microCouponData.receiveTotal = res.data.receive_total;
                this.microCouponData.useTotal = res.data.use_total_number;
                this.microCouponData.hasCoupon = Boolean(res.data.has_coupon);
            }
        },
        // 获取活动访客排行榜数据
        getVisitorRankData() {
            this.$ajax('get_activity_invite_rank', this.requestParams)
                .then((res) => {
                    if (res.data.code === 0) {
                        this.visitorRankData = res.data.data.list;
                        this.userVisitorRankData = res.data.data.my;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // 获取活动商机排行榜数据
        getBusinessRankData() {
            this.$ajax('get_activity_business_rank', this.requestParams)
                .then((res) => {
                    if (res.data.code === 0) {
                        this.businessRankData = res.data.data.list;
                        this.userBusinessRankData = res.data.data.my;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        handleActivityDetail(data) {
            this.info.activityTitle = data?.activity_title ?? ''; // 活动标题
            this.info.label = data?.keyword ?? ''; // 标签
            this.info.content = data.share_content; // 话术
            this.info.introduce = data.content; // 介绍
            this.info.total_promoters = data.total_promoters; // 推广人数
            this.distributeUrl = data.distribute_url; // 推广链接
            this.info.isJoin = data.is_join;
            this.isJoin = data.is_join; // 是否参与推广
            this.state = data.state; // 活动状态
            this.isMicroPage = data.resource_type === 18; // 18代表关联微页面
            // 海报信息
            this.posterData = data?.poster_conf ?? {}; // 基本坐标信息
            this.posterData.nickname = data?.staff_name ?? ''; // 推广员名字
            this.posterData.avatarSrc = this.avatarSrc; // 推广员头像
            this.posterData.qrcodeSrc = data.distribute_url ?? ''; // 二维码链接
        },
        goPromote() {
            this.isLoading = true;
            this.$refs.invitateDialog
                .getDistributeSeting()
                .then(() => {
                    if (!this.isJoin) {
                        this.getDistributeUrl();
                        this.isJoin = true;
                    } else {
                        this.isLoading = false;
                        this.isShowDialog = true;
                    }
                })
                .catch(() => {
                    this.isLoading = false;
                });
        },
        // 未参与推广时 需要调接口获取推广链接
        async getDistributeUrl() {
            if (!this.isJoin) {
                this.isLoading = true;
                try {
                    let res = await fetchDistributeUrl(this.params);
                    this.distributeUrl = res.data.distribute_url;
                    this.posterData.qrcodeSrc = this.distributeUrl; // 二维码链接
                    this.isLoading = false;
                } catch (e) {
                    console.log(e);
                    this.isLoading = false;
                }
            }
            this.isShowDialog = true;
        },
        // 首次进入活动详情接口加载完关闭loading
        loadedActivityList() {
            !this.isMicroLoading && (this.isLoading = false);
        }
    }
};
</script>

<style scoped lang="scss">
.activity-detail-box {
    .activity-detail {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        position: relative;
        z-index: 2;
        .normal-margin {
            padding: 0 0.32rem;
            margin-bottom: 0.32rem;
        }
        .coupon-margin {
            padding: 0 0.32rem;
            margin-top: -1rem;
            margin-bottom: 0.32rem;
        }
    }
    .background-img {
        width: 100%;
        background-image: linear-gradient(-5deg, rgba(255, 242, 233, 0) 4%, #ffd4b9 100%);
        height: 4.64rem;
        max-width: 7.5rem;
        position: absolute;
        top: -1.76rem;
        z-index: 1;
    }
}
</style>
