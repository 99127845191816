<template>
    <div class="activity-introduce">
        <div class="activity-title">
            <div class="name">
                {{ info.activityTitle }}
            </div>
            <div class="more" @click="toMoreActivity">推广中心></div>
        </div>
        <div class="label-list">
            <div class="label-item" v-for="(item, index) in info.label" :key="index">
                {{ item }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['info'],
    methods: {
        toMoreActivity() {
            // 活动列表会直接放到主页中显示 staffPromotion
            this.$router.push({ name: 'staffPromotion' });
        }
    }
};
</script>
<style scoped lang="scss">
.activity-introduce {
    width: 100%;
    padding: 0.24rem 0.32rem;
    box-sizing: border-box;
    .activity-title {
        font-size: 0.32rem;
        color: #8c3b05;
        font-weight: 600;
        word-break: break-all;
        position: relative;
        .name {
            width: calc(100% - 1.7rem);
        }
        .more {
            font-size: 0.25rem;
            float: right;
            color: #1472ff;
            position: absolute;
            right: 0;
            top: 0;
            width: 1.5rem;
            text-align: right;
        }
    }
    .label-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: 0.2rem;
        color: #f68d1c;
        .label-item {
            padding: 0.04rem 0.16rem;
            margin-right: 0.08rem;
            background: rgba(246, 141, 28, 0.1);
            border-radius: 0.04rem;
            margin-top: 0.16rem;
        }
        .fold {
            margin-left: auto !important;
            font-size: 0.2rem;
            color: #848484;
            margin-top: 0.2rem;
            cursor: pointer;
        }
    }
}
</style>
