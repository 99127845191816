<template>
    <div ref="box" class="invite-customer-box" v-fixIPhoneX="{ paddingBottom: '2.12rem' }">
        <div ref="list" class="invite-customer" :class="[!isCoupon ? 'need-top' : 'coupon-tabs']">
            <van-tabs
                v-if="showTab"
                v-model="active"
                @change="onChange"
                type="line"
                color="orange"
                swipeable
                animated
                shrink
                title-inactive-color="#000000"
                title-active-color="#efa040"
            >
                <van-tab title="访问用户" />
                <van-tab title="领券用户" />
                <van-tab title="用券用户" />
            </van-tabs>
            <div class="content-wrap">
                <render-list
                    :isCoupon="isCoupon"
                    :isMicroPage="isMicroPage"
                    :activityId="fetchParams.activity_id"
                    :customerList="customerList"
                    :isJoin="isJoin"
                    :isGray="isGray"
                    :isInList="isInList"
                    :needClue="true"
                    :isLoaded="isLoaded"
                    :active-tab="active"
                    :is-micro-page="isMicroPage"
                    :is-coupon="isCoupon"
                />
                <div
                    v-show="!isCanloadMore && !isShowLoadImg && customerList.length !== 0"
                    :class="['nodata-text', { 'coupon-tab': showTab }]"
                >
                    没有更多了~
                </div>
                <div class="img_load">
                    <img v-if="isShowLoadImg && index != 1" src="@/assets/images/loading.gif" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    fetchActivityList,
    fetchMicroCoupon,
    fetchCouponList,
    microCouponType,
    customerType
} from '../../../api/index';
import { throttle } from '../../../../../utils/tools';
import RenderList from './general/renderList.vue';
import { cancelAllRequest } from '@xiaoe/js-tools';
import { mapMutations } from 'vuex';

//常量
//tabs对应字段哈希表
const listType = new Map([
    [0, 'visit'], //访客用户
    [1, 'receive'], //领券用户
    [2, 'use'] //用券用户
]);

export default {
    props: ['isJoin', 'isInList', 'currentTab', 'fetchParams', 'isCoupon', 'isMicroPage', 'hasMicroCoupon'],
    components: {
        RenderList
    },
    data() {
        return {
            isGray: false,
            active: 0, // 目前激活的 tab 索引
            customerList: [], // 列表数据
            isLoaded: false,
            index: 1, // 页数
            size: 10, // 每页大小
            total: 0, // 数据总数
            LOADMOREHEIGHT: 20,
            isCanloadMore: true, // 是否有下一页数据
            isShowLoadImg: true, // 是否展示加载中图片
            isShowPullUp: false // 是否已经置顶
        };
    },

    watch: {
        currentTab: {
            handler() {
                if (this.active === 0) {
                    this.initData();
                }
            },
            deep: true
        }
    },

    computed: {
        // 是否展示 tab 类型列表
        showTab() {
            return this.isCoupon || (this.isMicroPage && this.hasMicroCoupon);
        }
    },

    mounted() {
        window.addEventListener('scroll', this.triggerScroll);
        this.get_user_role();
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.triggerScroll);
    },

    methods: {
        ...mapMutations(['setBusinessDetail']),

        // tab 切换
        onChange(index) {
            this.active = index;
            cancelAllRequest();
            this.initData();
        },

        initData() {
            this.$toast.loading();
            this.isLoaded = false;
            this.isCanloadMore = true;
            this.index = 1;
            this.customerList = [];
            this.askUserList();
        },
        get_user_role() {
            let bUserId = localStorage.getItem('b_user_id');
            // eslint-disable-next-line no-extra-boolean-cast
            if (!!bUserId) {
                this.askUserList();
            } else {
                this.$ajax('get_user_role').then((res) => {
                    localStorage.setItem('b_user_id', res.data.b_user_id);
                    this.askUserList();
                });
            }
        },
        getCustomerList(params) {
            if (this.active === 0) {
                return fetchActivityList(params, customerType.get(this.currentTab.code || 0));
            } else if (this.isCoupon) {
                params['list_type'] = listType.get(this.active);
                return fetchCouponList(params, 'COUPONVISITLIST');
            }
            return fetchMicroCoupon(params, microCouponType.get(this.active));
        },
        async askUserList() {
            if (!this.isCanloadMore) return;
            this.isCanloadMore = false;
            this.isShowLoadImg = true;
            let params = {
                ...this.fetchParams,
                user_id: localStorage.getItem('b_user_id'),
                page: this.index,
                page_size: this.size
            };
            let res;
            try {
                // 优惠券活动详情访客用户
                res = await this.getCustomerList(params);
            } catch (err) {
                this.isShowLoadImg = false;
                this.$emit('loadedActivityList');
                this.isLoaded = true;
            }
            this.$toast.clear();
            if (res.code === 0) {
                this.isGray = res?.data?.is_gray_business_opportunities ?? false;
                this.$emit('transGray', this.isGray);
                this.customerList = this.customerList.concat(res?.data?.list ?? []);
                this.$emit('loadedActivityList');
                this.isLoaded = true;
                this.total = res?.data?.total ?? res.data.list.length;
                this.index += 1;
                this.isShowLoadImg = false;
                // 解决点击商机返回的数据不完整导致推广数和锁客数不正确的问题
                const businessDetail = this.$store.getters['getBusinessDetail'];
                const { data = {} } = res;
                for (const key in businessDetail) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (!data.hasOwnProperty(key)) {
                        data[key] = businessDetail[key];
                    }
                }
                this.$store.commit('setBusinessDetail', res?.data ?? {});
                if (this.total > this.customerList.length) {
                    this.isCanloadMore = true;
                }
            }
        },

        pullUp() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },

        goCustomerDetail(id) {
            this.$router.push({
                name: 'customerDetail',
                query: {
                    customer_id: id
                }
            });
        },

        triggerScroll: throttle(function () {
            // scrollTop是滚动条滚动时，距离顶部的距离
            try {
                let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                //windowHeight是可视区的高度
                let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //scrollHeight是滚动条的总高度
                let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                //  置顶
                let box = this.$refs.box;
                // 加载
                if (scrollTop + windowHeight + 20 >= scrollHeight) {
                    // 到了这个就可以进行业务逻辑加载后台数据了
                    this.get_user_role();
                }
                // 是否置顶
                if (box && scrollTop > box.offsetTop) {
                    this.isShowPullUp = true;
                } else {
                    this.isShowPullUp = false;
                }
            } catch (e) {
                console.error(`listenScroll:${e}`);
            }
        })
    }
};
</script>

<style scoped lang="scss">
::v-deep .van-tab {
    flex: unset;
    padding: 0 0.28rem;
    font-size: 0.28rem;
    line-height: 0.4rem;
}
.invite-customer-box {
    padding-bottom: calc(1.44rem + env(safe-area-inset-bottom));
    margin-top: 0.32rem;
}
.invite-customer {
    margin-top: 0.32rem;
    width: 100%;
    padding: 0.24rem 0;
    padding-top: 0;
    box-sizing: border-box;
    border-top-left-radius: 0.32rem;
    border-top-right-radius: 0.32rem;
    min-height: calc(100vh - 4.88rem);
    .top-line {
        display: flex;
        flex-direction: row-reverse;
    }
    .head {
        width: 100%;
        background: #ffffff;
        padding: 0.16rem 0.32rem;
        box-sizing: border-box;
        position: sticky;
        display: flex;
        align-items: center;
        top: 0;
        border-bottom: 0.5px solid white;
        .title {
            font-size: 0.32rem;
            color: #333333;
            font-weight: 500;
        }
        .num {
            font-size: 0.28rem;
            color: #333333;
            font-weight: 500;
        }
        img {
            width: 0.48rem;
            height: 0.48rem;
        }
    }
    .list {
        width: 100%;
        padding: 0 0.32rem;
        box-sizing: border-box;
        padding-bottom: 0.36rem;
        .render-list {
            display: flex;
            align-items: center;
            height: 0.8rem;
            justify-content: space-between;
            margin-top: 0.12rem;
            padding-top: 0.24rem;
            img {
                width: 0.8rem;
                height: 0.8rem;
                border-radius: 100%;
            }
            .profile {
                display: flex;
            }
            .name {
                margin-left: 0.24rem;
                font-size: 0.28rem;
                color: #333333;
                flex: 1;
                font-weight: 500;
                height: 100%;
                border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
                display: flex;
                justify-content: space-between;
                padding-bottom: 0.36rem;
                align-items: flex-end;

                .nick-name {
                    // width: 80%;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 1;
                    display: flex;
                    flex-direction: column;
                    -webkit-box-orient: vertical;
                    white-space: normal;
                    .time {
                        color: #999999;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .nodata-text {
        display: flex;
        justify-content: center;
        color: #999;
        &.coupon-tab {
            padding: 0.32rem;
        }
    }
}
.need-top {
    padding: 0.24rem 0;
}
.no-customer {
    background: unset;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 0.28rem;
    color: #9e9e9e;
    img {
        width: 2.88rem;
        height: 2.88rem;
        margin-top: 1.6rem;
    }
}
.img_load {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
        width: 0.5rem;
        height: 0.5rem;
        padding: 0.2rem 0;
    }
}
.background-white {
    margin-top: 0.32rem;
    position: relative;
    background: #ffffff;
    min-height: calc(100vh - 2.88rem);
    border-top-left-radius: 0.32rem;
    border-top-right-radius: 0.32rem;
    .head {
        padding: 0.16rem 0.32rem;
        .title {
            font-size: 0.32rem;
            color: #333333;
            font-weight: 500;
        }
        .num {
            font-size: 0.28rem;
            color: #333333;
            font-weight: 500;
        }
        position: absolute;
        top: 0;
        left: 0;
    }
}
.box-shadow {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
}
.gray-color {
    color: #b2b2b2 !important;
}
.opacity {
    opacity: 0.4;
}
</style>
