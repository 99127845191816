import {emojiReg, emojiAllInfo} from "./parse";
import {emojiMap, emojiUrl} from "./emojiMap";
const emojiBgUrl = 'http://community-1252524126.file.myqcloud.com/img/ecommunity-8917964.png';

console.log(emojiAllInfo)

const currying = (reg)=> {
    return (content)=> {
        try {
            if (reg.test(content)) {
                content.replace(reg, ($1)=> {
                    // eslint-disable-next-line no-prototype-builtins
                    if (emojiMap.hasOwnProperty($1)) { // 这个是找对表情图
                        return (content = content.replace($1, `<img class="emoji" src="${emojiUrl + emojiMap[$1]}">`));
                    }
                    // eslint-disable-next-line no-prototype-builtins
                    if (emojiAllInfo.hasOwnProperty($1)) { // 这个是雪碧图
                        const { className, emoji } = emojiAllInfo[$1];
                        let repText = '';
                        if (emoji) {
                            repText = `<span class="emoji">${ emoji }</span>`;
                        } else {
                            repText = `<div class="emoji-parent"><div style="background-image: url(${emojiBgUrl});" class="emoji xb ${className}"></div></div>`;
                        }
                        return (content = content.replace($1, repText));
                    }
                });
            }
        } catch (e) {
            console.error(`replaceEmoji:${e}`)
        } finally {
            // eslint-disable-next-line no-unsafe-finally
            return content;
        }
    }
};

export const replaceEmoji = currying(emojiReg);
