<template>
    <div class="btn-box" v-fixIPhoneX="{ paddingBottom: '0.68rem' }">
        <div
            :class="['btn', state === 1 || couponState === 4 || couponState === 3 ? 'disabled-btn' : '']"
            @click="goPromote"
        >
            {{ couponState === 4 || couponState === 3 || state == 1 ? '活动已失效' : '去推广' }}
        </div>
    </div>
</template>
<script>
export default {
    props: ['state', 'couponState'],
    methods: {
        goPromote() {
            // 活动失效
            if (this.state === 1 || this.couponState == 4 || this.couponState == 3) {
                return;
            }
            this.$emit('goPromote');
        }
    }
};
</script>
<style>
.app {
    background: #f5f6f9;
    min-height: 100%;
    position: relative !important;
}
</style>
<style lang="scss">
.btn-box {
    position: fixed;
    width: 7.5rem;
    max-width: 7.5rem;
    height: 1.44rem;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    z-index: 3;
    .btn {
        width: 6.54rem;
        height: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.48rem;
        background-color: #fd4e1e;
        color: #ffffff;
        font-size: 0.28rem;
        font-weight: 500;
    }
}
.disabled-btn {
    background: #ebebeb !important;
    color: #999 !important;
}
</style>
