<template>
    <van-overlay :show="isShowWxShare">
        <div class="share-wrapper" @click="close">
            <div class="share-line">
                <div class="share-picture">
                    <img src="@/assets/mine/share-path.png" alt="share" />
                </div>
            </div>
            <div class="share-text">点击右上角 … 进行分享</div>
        </div>
    </van-overlay>
</template>
<script>
export default {
    props: ['isShowWxShare'],
    methods: {
        close() {
            this.$emit('close');
        }
    }
};
</script>
<style scoped lang="scss">
.share-wrapper {
    height: 100%;
    padding: 0.42rem 0.28rem;
    .share-line {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0.1rem;
    }
    .share-picture {
        width: 0.74rem;
        height: 1.28rem;
        margin-right: 0.44rem;
    }
    .share-text {
        line-height: 0.36rem;
        font-size: 0.26rem;
        color: #ffffff;
        text-align: right;
    }
}
</style>
<style lang="scss">
.activity-detail-box {
    .van-overlay {
        z-index: 999;
    }
}
</style>
