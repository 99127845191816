<template>
    <div class="box">
        <!-- <div :class="['head', 'only-head']" v-if="!isShowContent">
            <div class="left">
                已有
                <span class="num">{{ info.total_promoters }}</span>
                人参与推广
            </div>
            <div class="right" @click="isShowContent = !isShowContent">
                <span>活动详情</span>
                <img src="@/assets/images/promote_top.png" alt="" v-if="isShowContent" />
                <img src="@/assets/images/promote_bottom.png" alt="" v-else />
            </div>
        </div> -->
        <div class="content-detail">
            <div class="head">
                <div class="left">
                    已有
                    <span class="num">{{ info.total_promoters }}</span>
                    人参与推广
                </div>
                <div class="right" @click="isShowContent = !isShowContent" v-show="transferInfoContent">
                    <span>活动详情</span>
                    <img src="@/assets/images/promote_top.png" alt="" v-if="isShowContent" />
                    <img src="@/assets/images/promote_bottom.png" alt="" v-else />
                </div>
            </div>
            <!-- <div class="content-term" v-if="info.content && isShowContent" :style="styleVar">
                <div class="title">
                    <span>活动话术</span>
                    <span class="copybtn" @click="copyCheck">复制</span>
                    <span class="copybtnDom" ref="copyDom" @click="copyLink" :data-clipboard-text="copyText"></span>
                </div>
                <div class="content" ref="activityContentDom">
                    {{ info.content }}
                </div>
                <div class="more-than-handle" v-if="isMoreThan5Row">
                    <div @click="showAll = !showAll">
                        <span>{{ showAll ? '收起' : '展开' }}</span>
                        <i class="font_family iconfont icon-xia-copy" :class="{ 'up-arrow': showAll }" />
                    </div>
                </div>
            </div> -->
            <div class="content-term" v-if="isShowContent && transferInfoContent">
                <div class="title">
                    <span>活动介绍</span>
                </div>
                <div class="content" v-html="transferInfoContent"></div>
            </div>
        </div>
    </div>
</template>
<script>
import Clipboard from 'clipboard';
import Toast from '../../../components/toast';
import { replaceEmoji } from '../../../../../utils/emojiParse/index';

export default {
    props: ['info', 'distributeUrl'],
    computed: {
        headStyle() {
            return this.isShowContent;
        },
        copyText() {
            return this.info.content;
        },
        transferInfoContent() {
            return replaceEmoji(this.info.introduce);
        }
        // styleVar() {
        //     return {
        //         '--content-height': this.isMoreThan5Row && !this.showAll ? this.fiveRowsHeight + 'px' : ''
        //     };
        // }
    },
    data() {
        return {
            isShowContent: true,
            isMoreThan5Row: false,
            showAll: false,
            fiveRowsHeight: ''
        };
    },
    watch: {
        info: {
            handler(val) {
                if (!val.isJoin) {
                    this.isShowContent = true;
                }
            },
            deep: true
        }
        // 'info.content': {
        //     handler(val) {
        //         this.$nextTick(() => {
        //             val && this.judgeMoreThan5Row();
        //         });
        //     },
        //     immediate: true
        // }
    },
    methods: {
        async copyCheck() {
            await this.$parent.bindDistribute();
            // 兼容IOS
            this.$forceUpdate();
            setTimeout(() => {
                this.$refs.copyDom.click();
            }, 10);
        },
        copyLink(event) {
            event.preventDefault();
            // 设置复制按钮
            let clipboard = new Clipboard('.copybtnDom');
            clipboard.on('success', function (e) {
                console.log(e);
                Toast('复制话术成功');
                // 因为本页面有两个复制 所以复制成功后需要清除页面中的回调函数
                clipboard.e && (clipboard.e.success = []);
            });
            clipboard.on('error', function (e) {
                // that.copyBoxShow = true;
                console.error('Action:', e.action);
                console.error('Trigger:', e.trigger);
            });
        },
        //判断是否超过5行，总高度/(字体高度+ 行间距)  数字不是特别精确，待有更好的判断去判断
        judgeMoreThan5Row() {
            const contentStyle = window.getComputedStyle(this.$refs.activityContentDom);
            let contentHeight = Number(contentStyle.height.replace('px', ''));
            let fontSize = Number(contentStyle.fontSize.replace('px', ''));
            let times = Math.round(contentHeight / (fontSize + fontSize * 0.4));
            this.isMoreThan5Row = times > 5 ? true : false;
            this.fiveRowsHeight = Math.ceil((fontSize + fontSize * 0.4) * 5) + 4;
        }
    }
};
</script>
<style scoped lang="scss">
.box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.32rem;
    margin-top: 0.08rem;
    .content-detail {
        width: 100%;
        box-sizing: border-box;
        padding: 0.3rem 0.32rem;
        background: #ffffff;
        border-radius: 0.16rem;
        .content-term {
            margin-top: 0.3rem;
            padding-top: 0.3rem;
            border-top: 0.02rem solid #f5f5f5;
            .title {
                display: flex;
                align-items: center;
                font-size: 0.32rem;
                font-weight: 500;
                color: #333333;
                margin-bottom: 0.12rem;
            }
            .content {
                color: #666666;
                white-space: pre-line;
                word-break: break-all;
                font-weight: unset;
                height: var(--content-height);
                overflow: hidden;
                ::v-deep .emoji-parent {
                    position: relative;
                    width: 0.45rem;
                    height: 0.45rem;
                    display: inline-block;
                    .emoji {
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        transform-origin: -3% 13%;
                        &.xb {
                            width: 1.2rem;
                            height: 1.1rem;
                            transform: scale(0.363);
                        }
                    }
                }
                ::v-deep .emoji {
                    display: inline-block;
                    height: 0.4rem;
                    width: 0.4rem;
                    vertical-align: sub;
                    font-size: 0.35rem;
                    margin-left: 2px;
                    margin-right: 2px;
                }
            }
            .copybtn {
                display: inline-block;
                box-sizing: border-box;
                font-size: 0.2rem;
                color: #1472ff;
                margin-left: 0.16rem;
                font-weight: normal !important;
                width: 0.72rem;
                border-radius: 0.16rem;
                opacity: 1;
                text-align: center;
                border: 0.02rem solid #1472ff;
            }
        }
    }
}
.head {
    font-size: 0.28rem;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-bottom: 0.26rem;
    height: 0.43rem;
    box-sizing: border-box;
    .num {
        font-size: 0.48rem;
        color: #f68d1c;
        font-family: D-DIN-Bold;
        margin: 0 0.08rem;
    }
    .left {
        display: flex;
        align-items: center;
    }
    .right {
        color: #999999;
        display: flex;
        align-items: center;
        font-size: 0.24rem;
        img {
            width: 0.32rem;
            height: 0.32rem;
            margin-left: 0.08rem;
            position: relative;
            top: -0.02rem;
        }
    }
}
.only-head {
    height: 0.96rem;
    background: white;
    box-sizing: border-box;
    padding: 0.3rem 0.32rem 0.26rem 0.32rem;
    border-radius: 0.16rem;
}
.more-than-handle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.24rem;
    div {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    i,
    span {
        color: #1472ff;
    }
    span {
        font-size: 0.24rem;
    }
    i {
        font-size: 0.32rem;
    }
    .up-arrow {
        transform: rotate(180deg);
    }
}
</style>
