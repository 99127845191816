/*
 * @Author: keke
 * @Date: 2022-02-22 15:26:24
 * @LastEditors: keke
 * @LastEditTime: 2022-02-22 18:01:50
 * @Description:
 */
// emoji客户端字符数据源  id -表情编号(用来表情面板关联)  cn-[中文]  hk-[繁体]  us-[英文] code-/:*  web_code-/中文
export default [
    { 'id': 0, 'cn': '[微笑]', 'hk': '[微笑]', 'us': '[Smile]', 'code': '/::)', 'web_code': '/微笑', 'className': 'smiley_0' }
    , { 'id': 1, 'cn': '[撇嘴]', 'hk': '[撇嘴]', 'us': '[Grimace]', 'code': '/::~', 'web_code': '/撇嘴', 'className': 'smiley_1' }
    , { 'id': 2, 'cn': '[色]', 'hk': '[色]', 'us': '[Drool]', 'code': '/::B', 'web_code': '/色', 'className': 'smiley_2' }
    , { 'id': 3, 'cn': '[发呆]', 'hk': '[發呆]', 'us': '[Scowl]', 'code': '/::|', 'web_code': '/发呆', 'className': 'smiley_3' }
    , { 'id': 4, 'cn': '[得意]', 'hk': '[得意]', 'us': '[CoolGuy]', 'code': '/:8-)', 'web_code': '/得意', 'className': 'smiley_4' }
    , { 'id': 5, 'cn': '[流泪]', 'hk': '[流淚]', 'us': '[Sob]', 'code': '/::<', 'web_code': '/流泪', 'className': 'smiley_5' }
    , { 'id': 6, 'cn': '[害羞]', 'hk': '[害羞]', 'us': '[Shy]', 'code': '/::$', 'web_code': '/害羞', 'className': 'smiley_6' }
    , { 'id': 7, 'cn': '[闭嘴]', 'hk': '[閉嘴]', 'us': '[Silent]', 'code': '/::X', 'web_code': '/闭嘴', 'className': 'smiley_7' }
    , { 'id': 8, 'cn': '[睡]', 'hk': '[睡]', 'us': '[Sleep]', 'code': '/::Z', 'web_code': '/睡', 'className': 'smiley_8' }
    , { 'id': 9, 'cn': '[大哭]', 'hk': '[大哭]', 'us': '[Cry]', 'code': '/::"(', 'web_code': '/大哭', 'className': 'smiley_9' }
    , { 'id': 10, 'cn': '[尴尬]', 'hk': '[尷尬]', 'us': '[Awkward]', 'code': '/::-|', 'web_code': '/尴尬', 'className': 'smiley_10' }
    , { 'id': 11, 'cn': '[发怒]', 'hk': '[發怒]', 'us': '[Angry]', 'code': '/::@', 'web_code': '/发怒', 'className': 'smiley_11' }
    , { 'id': 12, 'cn': '[调皮]', 'hk': '[調皮]', 'us': '[Tongue]', 'code': '/::P', 'web_code': '/调皮', 'className': 'smiley_12' }
    , { 'id': 13, 'cn': '[呲牙]', 'hk': '[呲牙]', 'us': '[Grin]', 'code': '/::D', 'web_code': '/呲牙', 'className': 'smiley_13' }
    , { 'id': 14, 'cn': '[惊讶]', 'hk': '[驚訝]', 'us': '[Surprise]', 'code': '/::O', 'web_code': '/惊讶', 'className': 'smiley_14' }
    , { 'id': 15, 'cn': '[难过]', 'hk': '[難過]', 'us': '[Frown]', 'code': '/::(', 'web_code': '/难过', 'className': 'smiley_15' }
    , { 'id': 16, 'cn': '[酷]', 'hk': '[酷]', 'us': '[Ruthless]', 'code': '/::+', 'web_code': '/酷', 'className': 'smiley_16' }
    , { 'id': 17, 'cn': '[冷汗]', 'hk': '[冷汗]', 'us': '[Blush]', 'code': '/:--b', 'web_code': '/冷汗', 'className': 'smiley_17' }
    , { 'id': 18, 'cn': '[抓狂]', 'hk': '[抓狂]', 'us': '[Scream]', 'code': '/::Q', 'web_code': '/抓狂', 'className': 'smiley_18' }
    , { 'id': 19, 'cn': '[吐]', 'hk': '[吐]', 'us': '[Puke]', 'code': '/::T', 'web_code': '/吐', 'className': 'smiley_19' }
    , { 'id': 20, 'cn': '[偷笑]', 'hk': '[偷笑]', 'us': '[Chuckle]', 'code': '/:,@P', 'web_code': '/偷笑', 'className': 'smiley_20' }
    , { 'id': 21, 'cn': '[愉快]', 'hk': '[愉快]', 'us': '[Joyful]', 'code': '/:,@-D', 'web_code': '/可爱', 'className': 'smiley_21' }
    , { 'id': 22, 'cn': '[白眼]', 'hk': '[白眼]', 'us': '[Slight]', 'code': '/::d', 'web_code': '/白眼', 'className': 'smiley_22' }
    , { 'id': 23, 'cn': '[傲慢]', 'hk': '[傲慢]', 'us': '[Smug]', 'code': '/:,@o', 'web_code': '/傲慢', 'className': 'smiley_23' }
    , { 'id': 24, 'cn': '[饥饿]', 'hk': '[饑餓]', 'us': '[Hungry]', 'code': '/::g', 'web_code': '/饥饿', 'className': 'smiley_24' }
    , { 'id': 25, 'cn': '[困]', 'hk': '[累]', 'us': '[Drowsy]', 'code': '/:|-)', 'web_code': '/困', 'className': 'smiley_25' }
    , { 'id': 26, 'cn': '[惊恐]', 'hk': '[驚恐]', 'us': '[Panic]', 'code': '/::!', 'web_code': '/惊恐', 'className': 'smiley_26' }
    , { 'id': 27, 'cn': '[流汗]', 'hk': '[流汗]', 'us': '[Sweat]', 'code': '/::L', 'web_code': '/流汗', 'className': 'smiley_27' }
    , { 'id': 28, 'cn': '[憨笑]', 'hk': '[大笑]', 'us': '[Laugh]', 'code': '/::>', 'web_code': '/憨笑', 'className': 'smiley_28' }
    , { 'id': 29, 'cn': '[悠闲]', 'hk': '[悠閑]', 'us': '[Commando]', 'code': '/::,@', 'web_code': '/大兵', 'className': 'smiley_29' }
    , { 'id': 30, 'cn': '[奋斗]', 'hk': '[奮鬥]', 'us': '[Determined]', 'code': '/:,@f', 'web_code': '/奋斗', 'className': 'smiley_30' }
    , { 'id': 31, 'cn': '[咒骂]', 'hk': '[咒罵]', 'us': '[Scold]', 'code': '/::-S', 'web_code': '/咒骂', 'className': 'smiley_31' }
    , { 'id': 32, 'cn': '[疑问]', 'hk': '[疑問]', 'us': '[Shocked]', 'code': '/:?', 'web_code': '/疑问', 'className': 'smiley_32' }
    , { 'id': 33, 'cn': '[嘘]', 'hk': '[噓]', 'us': '[Shhh]', 'code': '/:,@x', 'web_code': '/嘘', 'className': 'smiley_33' }
    , { 'id': 34, 'cn': '[晕]', 'hk': '[暈]', 'us': '[Dizzy]', 'code': '/:,@@', 'web_code': '/晕', 'className': 'smiley_34' }
    , { 'id': 35, 'cn': '[疯了]', 'hk': '[瘋了]', 'us': '[Tormented]', 'code': '/::8', 'web_code': '/折磨', 'className': 'smiley_35' }
    , { 'id': 36, 'cn': '[衰]', 'hk': '[衰]', 'us': '[Toasted]', 'code': '/:,@!', 'web_code': '/衰', 'className': 'smiley_36' }
    , { 'id': 37, 'cn': '[骷髅]', 'hk': '[骷髏頭]', 'us': '[Skull]', 'code': '/:!!!', 'web_code': '/骷髅', 'className': 'smiley_37' }
    , { 'id': 38, 'cn': '[敲打]', 'hk': '[敲打]', 'us': '[Hammer]', 'code': '/:xx', 'web_code': '/敲打', 'className': 'smiley_38' }
    , { 'id': 39, 'cn': '[再见]', 'hk': '[再見]', 'us': '[Wave]', 'code': '/:bye', 'web_code': '/再见', 'className': 'smiley_39' }
    , { 'id': 40, 'cn': '[擦汗]', 'hk': '[擦汗]', 'us': '[Speechless]', 'code': '/:wipe', 'web_code': '/擦汗', 'className': 'smiley_40' }
    , { 'id': 41, 'cn': '[抠鼻]', 'hk': '[摳鼻]', 'us': '[NosePick]', 'code': '/:dig', 'web_code': '/抠鼻', 'className': 'smiley_41' }
    , { 'id': 42, 'cn': '[鼓掌]', 'hk': '[鼓掌]', 'us': '[Clap]', 'code': '/:handclap', 'web_code': '/鼓掌', 'className': 'smiley_42' }
    , { 'id': 43, 'cn': '[糗大了]', 'hk': '[羞辱]', 'us': '[Shame]', 'code': '/:&-(', 'web_code': '/糗大了', 'className': 'smiley_43' }
    , { 'id': 44, 'cn': '[坏笑]', 'hk': '[壞笑]', 'us': '[Trick]', 'code': '/:B-)', 'web_code': '/坏笑', 'className': 'smiley_44' }
    , { 'id': 45, 'cn': '[左哼哼]', 'hk': '[左哼哼]', 'us': '[Bah！L]', 'code': '/:<@', 'web_code': '/左哼哼', 'className': 'smiley_45' }
    , { 'id': 46, 'cn': '[右哼哼]', 'hk': '[右哼哼]', 'us': '[Bah！R]', 'code': '/:@>', 'web_code': '/右哼哼', 'className': 'smiley_46' }
    , { 'id': 47, 'cn': '[哈欠]', 'hk': '[哈欠]', 'us': '[Yawn]', 'code': '/::-O', 'web_code': '/哈欠', 'className': 'smiley_47' }
    , { 'id': 48, 'cn': '[鄙视]', 'hk': '[鄙視]', 'us': '[Pooh-pooh]', 'code': '/:>-|', 'web_code': '/鄙视', 'className': 'smiley_48' }
    , { 'id': 49, 'cn': '[委屈]', 'hk': '[委屈]', 'us': '[Shrunken]', 'code': '/:P-(', 'web_code': '/委屈', 'className': 'smiley_49' }
    , { 'id': 50, 'cn': '[快哭了]', 'hk': '[快哭了]', 'us': '[TearingUp]', 'code': '/::"|', 'web_code': '/快哭了', 'className': 'smiley_50' }
    , { 'id': 51, 'cn': '[阴险]', 'hk': '[陰險]', 'us': '[Sly]', 'code': '/:X-)', 'web_code': '/阴险', 'className': 'smiley_51' }
    , { 'id': 52, 'cn': '[亲亲]', 'hk': '[親親]', 'us': '[Kiss]', 'code': '/::*', 'web_code': '/亲亲', 'className': 'smiley_52' }
    , { 'id': 53, 'cn': '[吓]', 'hk': '[嚇]', 'us': '[Wrath]', 'code': '/:@x', 'web_code': '/吓', 'className': 'smiley_53' }
    , { 'id': 54, 'cn': '[可怜]', 'hk': '[可憐]', 'us': '[Whimper]', 'code': '/:8*', 'web_code': '/可怜', 'className': 'smiley_54' }
    , { 'id': 55, 'cn': '[菜刀]', 'hk': '[菜刀]', 'us': '[Cleaver]', 'code': '/:pd', 'web_code': '/菜刀', 'className': 'smiley_55' }
    , { 'id': 56, 'cn': '[西瓜]', 'hk': '[西瓜]', 'us': '[Watermelon]', 'code': '/:<W>', 'web_code': '/西瓜', 'className': 'smiley_56' }
    , { 'id': 57, 'cn': '[啤酒]', 'hk': '[啤酒]', 'us': '[Beer]', 'code': '/:beer', 'web_code': '/啤酒', 'className': 'smiley_57' }
    , { 'id': 58, 'cn': '[篮球]', 'hk': '[籃球]', 'us': '[Basketball]', 'code': '/:basketb', 'web_code': '/篮球', 'className': 'smiley_58' }
    , { 'id': 59, 'cn': '[乒乓]', 'hk': '[乒乓]', 'us': '[PingPong]', 'code': '/:oo', 'web_code': '/乒乓', 'className': 'smiley_59' }
    , { 'id': 60, 'cn': '[咖啡]', 'hk': '[咖啡]', 'us': '[Coffee]', 'code': '/:coffee', 'web_code': '/咖啡', 'className': 'smiley_60' }
    , { 'id': 61, 'cn': '[饭]', 'hk': '[飯]', 'us': '[Rice]', 'code': '/:eat', 'web_code': '/饭', 'className': 'smiley_61' }
    , { 'id': 62, 'cn': '[猪头]', 'hk': '[豬頭]', 'us': '[Pig]', 'code': '/:pig', 'web_code': '/猪头', 'className': 'smiley_62' }
    , { 'id': 63, 'cn': '[玫瑰]', 'hk': '[玫瑰]', 'us': '[Rose]', 'code': '/:rose', 'web_code': '/玫瑰', 'className': 'smiley_63' }
    , { 'id': 64, 'cn': '[凋谢]', 'hk': '[枯萎]', 'us': '[Wilt]', 'code': '/:fade', 'web_code': '/凋谢', 'className': 'smiley_64' }
    , { 'id': 65, 'cn': '[嘴唇]', 'hk': '[嘴唇]', 'us': '[Lips]', 'code': '/:showlove', 'web_code': '/示爱', 'className': 'smiley_65' }
    , { 'id': 66, 'cn': '[爱心]', 'hk': '[愛心]', 'us': '[Heart]', 'code': '/:heart', 'web_code': '/爱心', 'className': 'smiley_66' }
    , { 'id': 67, 'cn': '[心碎]', 'hk': '[心碎]', 'us': '[BrokenHeart]', 'code': '/:break', 'web_code': '/心碎', 'className': 'smiley_67' }
    , { 'id': 68, 'cn': '[蛋糕]', 'hk': '[蛋糕]', 'us': '[Cake]', 'code': '/:cake', 'web_code': '/蛋糕', 'className': 'smiley_68' }
    , { 'id': 69, 'cn': '[闪电]', 'hk': '[閃電]', 'us': '[Lightning]', 'code': '/:li', 'web_code': '/闪电', 'className': 'smiley_69' }
    , { 'id': 70, 'cn': '[炸弹]', 'hk': '[炸彈]', 'us': '[Bomb]', 'code': '/:bome', 'web_code': '/炸弹', 'className': 'smiley_70' }
    , { 'id': 71, 'cn': '[刀]', 'hk': '[刀]', 'us': '[Dagger]', 'code': '/:kn', 'web_code': '/刀', 'className': 'smiley_71' }
    , { 'id': 72, 'cn': '[足球]', 'hk': '[足球]', 'us': '[Soccer]', 'code': '/:footb', 'web_code': '/足球', 'className': 'smiley_72' }
    , { 'id': 73, 'cn': '[瓢虫]', 'hk': '[甲蟲]', 'us': '[Ladybug]', 'code': '/:ladybug', 'web_code': '/瓢虫', 'className': 'smiley_73' }
    , { 'id': 74, 'cn': '[便便]', 'hk': '[便便]', 'us': '[Poop]', 'code': '/:shit', 'web_code': '/便便', 'className': 'smiley_74' }
    , { 'id': 75, 'cn': '[月亮]', 'hk': '[月亮]', 'us': '[Moon]', 'code': '/:moon', 'web_code': '/月亮', 'className': 'smiley_75' }
    , { 'id': 76, 'cn': '[太阳]', 'hk': '[太陽]', 'us': '[Sun]', 'code': '/:sun', 'web_code': '/太阳', 'className': 'smiley_76' }
    , { 'id': 77, 'cn': '[礼物]', 'hk': '[禮物]', 'us': '[Gift]', 'code': '/:gift', 'web_code': '/礼物', 'className': 'smiley_77' }
    , { 'id': 78, 'cn': '[拥抱]', 'hk': '[擁抱]', 'us': '[Hug]', 'code': '/:hug', 'web_code': '/拥抱', 'className': 'smiley_78' }
    , { 'id': 79, 'cn': '[强]', 'hk': '[強]', 'us': '[ThumbsUp]', 'code': '/:strong', 'web_code': '/强', 'className': 'smiley_79' }
    , { 'id': 80, 'cn': '[弱]', 'hk': '[弱]', 'us': '[ThumbsDown]', 'code': '/:weak', 'web_code': '/弱', 'className': 'smiley_80' }
    , { 'id': 81, 'cn': '[握手]', 'hk': '[握手]', 'us': '[Shake]', 'code': '/:share', 'web_code': '/握手', 'className': 'smiley_81' }
    , { 'id': 82, 'cn': '[胜利]', 'hk': '[勝利]', 'us': '[Peace]', 'code': '/:v', 'web_code': '/胜利', 'className': 'smiley_82' }
    , { 'id': 83, 'cn': '[抱拳]', 'hk': '[抱拳]', 'us': '[Fight]', 'code': '/:@)', 'web_code': '/抱拳', 'className': 'smiley_83' }
    , { 'id': 84, 'cn': '[勾引]', 'hk': '[勾引]', 'us': '[Beckon]', 'code': '/:jj', 'web_code': '/勾引', 'className': 'smiley_84' }
    , { 'id': 85, 'cn': '[拳头]', 'hk': '[拳頭]', 'us': '[Fist]', 'code': '/:@@', 'web_code': '/拳头', 'className': 'smiley_85' }
    , { 'id': 86, 'cn': '[差劲]', 'hk': '[差勁]', 'us': '[Pinky]', 'code': '/:bad', 'web_code': '/差劲', 'className': 'smiley_86' }
    , { 'id': 87, 'cn': '[爱你]', 'hk': '[愛你]', 'us': '[RockOn]', 'code': '/:lvu', 'web_code': '/爱你', 'className': 'smiley_87' }
    , { 'id': 88, 'cn': '[NO]', 'hk': '[NO]', 'us': '[Nuh-uh]', 'code': '/:no', 'web_code': '/NO', 'className': 'smiley_88' }
    , { 'id': 89, 'cn': '[OK]', 'hk': '[OK]', 'us': '[OK]', 'code': '/:ok', 'web_code': '/OK', 'className': 'smiley_89' }
    , { 'id': 90, 'cn': '[爱情]', 'hk': '[愛情]', 'us': '[InLove]', 'code': '/:love', 'web_code': '/爱情', 'className': 'smiley_90' }
    , { 'id': 91, 'cn': '[飞吻]', 'hk': '[飛吻]', 'us': '[Blowkiss]', 'code': '/:<L>', 'web_code': '/飞吻', 'className': 'smiley_91' }
    , { 'id': 92, 'cn': '[跳跳]', 'hk': '[跳跳]', 'us': '[Waddle]', 'code': '/:jump', 'web_code': '/跳跳', 'className': 'smiley_92' }
    , { 'id': 93, 'cn': '[发抖]', 'hk': '[發抖]', 'us': '[Tremble]', 'code': '/:shake', 'web_code': '/发抖', 'className': 'smiley_93' }
    , { 'id': 94, 'cn': '[怄火]', 'hk': '[噴火]', 'us': '[Aaagh!]', 'code': '/:<O>', 'web_code': '/怄火', 'className': 'smiley_94' }
    , { 'id': 95, 'cn': '[转圈]', 'hk': '[轉圈]', 'us': '[Twirl]', 'code': '/:circle', 'web_code': '/转圈', 'className': 'smiley_95' }
    , { 'id': 96, 'cn': '[磕头]', 'hk': '[磕頭]', 'us': '[Kotow]', 'code': '/:kotow', 'web_code': '/磕头', 'className': 'smiley_96' }
    , { 'id': 97, 'cn': '[回头]', 'hk': '[回頭]', 'us': '[Dramatic]', 'code': '/:turn', 'web_code': '/回头', 'className': 'smiley_97' }
    , { 'id': 98, 'cn': '[跳绳]', 'hk': '[跳繩]', 'us': '[JumpRope]', 'code': '/:skip', 'web_code': '/跳绳', 'className': 'smiley_98' }
    , { 'id': 99, 'cn': '[投降]', 'hk': '[投降]', 'us': '[Surrender]', 'code': '/:oY', 'web_code': '/挥手', 'className': 'smiley_99' }
    , { 'id': 100, 'cn': '[激动]', 'hk': '[激動]', 'us': '[Hooray]', 'code': '/:#-0', 'web_code': '/激动', 'className': 'smiley_100' }
    , { 'id': 101, 'cn': '[乱舞]', 'hk': '[亂舞]', 'us': '[Meditate]', 'code': '/:hiphot', 'web_code': '/街舞', 'className': 'smiley_101' }
    , { 'id': 102, 'cn': '[献吻]', 'hk': '[獻吻]', 'us': '[Smooch]', 'code': '/:kiss', 'web_code': '/献吻', 'className': 'smiley_102' }
    , { 'id': 103, 'cn': '[左太极]', 'hk': '[左太極]', 'us': '[TaiChi L]', 'code': '/:<&', 'web_code': '/左太极', 'className': 'smiley_103' }
    , { 'id': 104, 'cn': '[右太极]', 'hk': '[右太極]', 'us': '[TaiChi R]', 'code': '/:&>', 'web_code': '/右太极', 'className': 'smiley_104' }
    , { 'id': 204, 'cn': '[嘿哈]', 'hk': '[吼嘿]', 'us': '[Hey]', 'code': '', 'web_code': '', 'className': 'e2_04' }
    , { 'id': 205, 'cn': '[捂脸]', 'hk': '[掩面]', 'us': '[Facepalm]', 'code': '', 'web_code': '', 'className': 'e2_05' }
    , { 'id': 202, 'cn': '[奸笑]', 'hk': '[奸笑]', 'us': '[Smirk]', 'code': '', 'web_code': '', 'className': 'e2_02' }
    , { 'id': 206, 'cn': '[机智]', 'hk': '[機智]', 'us': '[Smart]', 'code': '', 'web_code': '', 'className': 'e2_06' }
    , { 'id': 212, 'cn': '[皱眉]', 'hk': '[皺眉]', 'us': '[Moue]', 'code': '', 'web_code': '', 'className': 'e2_12' }
    , { 'id': 211, 'cn': '[耶]', 'hk': '[歐耶]', 'us': '[Yeah!]', 'code': '', 'web_code': '', 'className': 'e2_11' }
    , { 'id': 207, 'cn': '[茶]', 'hk': '[茶]', 'us': '[Tea]', 'code': '', 'web_code': '', 'className': 'e2_07' }
    , { 'id': 209, 'cn': '[红包]', 'hk': '[Packet]', 'us': '[Packet]', 'code': '', 'web_code': '', 'className': 'e2_09' }
    , { 'id': 210, 'cn': '[蜡烛]', 'hk': '[蠟燭]', 'us': '[Candle]', 'code': '', 'web_code': '', 'className': 'e2_10' }
    , { 'id': 215, 'cn': '[福]', 'hk': '[福]', 'us': '[Blessing]', 'code': '', 'web_code': '', 'className': 'e2_15' }
    , { 'id': 214, 'cn': '[鸡]', 'hk': '[小雞]', 'us': '[Chick]', 'code': '', 'web_code': '', 'className': 'e2_14' }
    , { 'id': 300, 'cn': '[笑脸]', 'emoji': '😄', 'hk': '', 'us': '', 'code': '\\ue415', 'web_code': '', 'className': 'u1F604' }
    , { 'id': 301, 'cn': '[生病]', 'emoji': '😷', 'hk': '', 'us': '', 'code': '\\ue40c', 'web_code': '', 'className': 'u1F637' }
    , { 'id': 302, 'cn': '[破涕为笑]', 'emoji': '😂', 'hk': '', 'us': '', 'code': '\\ue412', 'web_code': '', 'className': 'u1F602' }
    , { 'id': 303, 'cn': '[吐舌]', 'emoji': '😝', 'hk': '', 'us': '', 'code': '\\ue409', 'web_code': '', 'className': 'u1F61D' }
    , { 'id': 304, 'cn': '[脸红]', 'emoji': '😳', 'hk': '', 'us': '', 'code': '\\ue40d', 'web_code': '', 'className': 'u1F633' }
    , { 'id': 305, 'cn': '[恐惧]', 'emoji': '😱', 'hk': '', 'us': '', 'code': '\\ue107', 'web_code': '', 'className': 'u1F631' }
    , { 'id': 306, 'cn': '[失望]', 'emoji': '😔', 'hk': '', 'us': '', 'code': '\\ue403', 'web_code': '', 'className': 'u1F614' }
    , { 'id': 307, 'cn': '[无语]', 'emoji': '😒', 'hk': '', 'us': '', 'code': '\\ue40e', 'web_code': '', 'className': 'u1F612' }
    , { 'id': 308, 'cn': '[鬼魂]', 'emoji': '👻', 'hk': '', 'us': '', 'code': '\\ue11b', 'web_code': '', 'className': 'u1F47B' }
    , { 'id': 309, 'cn': '[合十]', 'emoji': '🙏', 'hk': '', 'us': '', 'code': '\\ue41d', 'web_code': '', 'className': 'u1F64F' }
    , { 'id': 310, 'cn': '[强壮]', 'emoji': '💪', 'hk': '', 'us': '', 'code': '\\ue14c', 'web_code': '', 'className': 'u1F4AA' }
    , { 'id': 311, 'cn': '[庆祝]', 'emoji': '🎉', 'hk': '', 'us': '', 'code': '\\ue312', 'web_code': '', 'className': 'u1F389' }
    , { 'id': 312, 'cn': '[礼物]', 'hk': '', 'us': '', 'code': '\\ue112', 'web_code': '', 'className': 'u1F381' }
    , { 'id': 313, 'cn': '[吃瓜]', 'hk': '[]', 'us': '[]', 'code': '', 'web_code': '', 'className': 'smiley_313' }
    , { 'id': 314, 'cn': '[加油]', 'hk': '[]', 'us': '[]', 'code': '', 'web_code': '', 'className': 'smiley_314' }
    , { 'id': 315, 'cn': '[汗]', 'hk': '[]', 'us': '[]', 'code': '', 'web_code': '', 'className': 'smiley_315' }
    , { 'id': 316, 'cn': '[天啊]', 'hk': '[]', 'us': '[]', 'code': '', 'web_code': '', 'className': 'smiley_316' }
    , { 'id': 317, 'cn': '[Emm]', 'hk': '[]', 'us': '[]', 'code': '', 'web_code': '', 'className': 'smiley_317' }
    , { 'id': 318, 'cn': '[社会社会]', 'hk': '[]', 'us': '[]', 'code': '', 'web_code': '', 'className': 'smiley_318' }
    , { 'id': 319, 'cn': '[旺柴]', 'hk': '[]', 'us': '[]', 'code': '', 'web_code': '', 'className': 'smiley_319' }
    , { 'id': 320, 'cn': '[好的]', 'hk': '[]', 'us': '[]', 'code': '', 'web_code': '', 'className': 'smiley_320' }
    , { 'id': 321, 'cn': '[打脸]', 'hk': '[]', 'us': '[]', 'code': '', 'web_code': '', 'className': 'smiley_321' }
    , { 'id': 322, 'cn': '[哇]', 'hk': '[]', 'us': '[]', 'code': '', 'web_code': '', 'className': 'smiley_322' }
    , { 'id': 323, 'cn': '[翻白眼]', 'emoji': '🙄', 'hk': '[]', 'us': '[]', 'code': '', 'web_code': '', 'className': '' }
]


